<template>
  <div style="padding: 10px 10px 80px 10px;background-color: white;border-radius: 14px 14px 0 0;">
    <div style="font-weight: bold;margin: 8px 0 20px 6px" @click="toNodeDetail">审批流程 -
      <span style="font-size: 10px;font-weight: normal;color: #409EFF">节点详情</span>
    </div>
    <div>
      <el-timeline>
        <el-timeline-item v-for="(nodeItem, index) in approver" :key="index" placement="top">
          <div v-if="!nodeItem.hasOwnProperty('options')" class="card">
            <div style="display: flex;justify-content: space-between">
              <div style="margin-bottom: 10px">
                <span class="node-title">{{nodeItem.title}}</span>
                <span v-if="nodeItem.desc"> - </span>
                <span class="node-describe">{{nodeItem.desc}}</span>
              </div>
              <div>
                <el-image v-if="nodeItem.icon == 'end'" :src="require('/src/assets/image/agree.png')" style="width: 20px; height: 20px"/>
                <el-image v-if="nodeItem.icon == 'refuse'" :src="require('/src/assets/image/refuse-tool.png')" style="width: 20px; height: 20px"/>
                <span v-if="nodeItem.icon == 'running'" style="font-size: 17px" class="el-icon-loading"></span>
              </div>
            </div>

            <div style="display: flex;justify-content: space-between;flex-wrap: wrap">
              <div v-for="(user, index) in nodeItem.users"  :key="index">
                <div class="user-item" v-if="user" @click="selectUserComment(nodeItem,index)">
                  <div class="user-state" v-if="user.state">
                    <el-image v-if="user.state == 'consent'" :src="require('/src/assets/image/agree.png')" style="width: 16px; height: 16px"/>
                    <el-image v-if="user.state == 'refuse'" :src="require('/src/assets/image/refuse-tool.png')" style="width: 16px; height: 16px"/>
                  </div>
                  <el-avatar shape="square" class="user-avatar" :src="user.avatar"></el-avatar>
                  <div class="user-name">{{user.name}} </div>
                </div>
              </div>
              <div v-for="(item) in 30" style="width: 60px"></div>
            </div>
          </div>
        </el-timeline-item>
      </el-timeline>
    </div>

    <el-drawer
        destroy-on-close
        title="评论信息"
        :modal="false"
        size="540px"
        direction="btt"
        :visible.sync="userCommentDrawer"
    >
      <div v-if="userComment">
        <div v-if="userComment.commentVOList!=null && userComment.commentVOList.length>0" class="comment-item">
          <div style="font-size: 14px;margin:6px 0px 2px 0px;font-weight: bold;text-align: center">评论</div>
            <div v-for="(item,index) in userComment.commentVOList" :key="index" class="comment-info">
              {{item.comments}}
            </div>
          </div>
        <div v-if="userComment.optionVOList!=null && userComment.optionVOList.length>0" class="comment-item">
          <div style="font-size: 14px;margin:6px 0px 2px 0px;font-weight: bold;text-align: center">审批</div>
          <div v-for="(item,index) in userComment.optionVOList" :key="index" class="comment-info">
            {{item.comments}}
          </div>
        </div>

        <div v-if="userComment.attachmentVOList!=null && userComment.attachmentVOList.length>0" class="comment-item">
          <div style="font-size: 14px;margin:6px 0px 2px 0px;font-weight: bold;text-align: center">附件</div>
          <div style="display: flex;justify-content: space-between;flex-wrap: wrap;padding-bottom: 10px">
            <div v-for="(item,index) in userComment.attachmentVOList" :key="index">
              <el-image :preview-src-list="toList(userComment.attachmentVOList)" fit="cover" :src="item.url"
                        style="width: 60px;height: 60px;border-radius: 6px;background-color: white"/>
            </div>
            <div v-for="(item) in 30" style="width: 60px"></div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { selectApprover } from "@/api/org";
export default {
  name: "Approver",
  data() {
    return {
      startUserId:null,
      approver:[],
      a:[],
      process:{},
      userComment:null,
      userCommentDrawer:false,
      conditionFormItem: new Set(),
      initiator: {},
    }
  },
  methods:{
    toList(list){
      let imageList = [];
      for (const item of list) {
        imageList.push(item.url);
      }
      return imageList;
    },
    selectUserComment(nodeItem,index){
      let userList = this.$store.state.detailVOList[nodeItem.id];
      this.userComment = userList[index];
      this.userCommentDrawer = true;
      console.log(this.userComment)
    },
    toNodeDetail(){
      this.$router.push({
        path: "/workspaceMini/process/instance/tabs/processDiagramViewer"
      });
    },
    selectApprover(processInstanceId){
      let query = {list:this.approver,userId:this.startUserId,"processInstanceId":processInstanceId}
      selectApprover(query).then(val => {
        this.approver = JSON.parse(JSON.stringify(val.data));
        for (const item of this.approver) {
          if (this.$store.state.runningList.includes(item.id)) {
            item.icon = "running";
          }
          else if (this.$store.state.endList.includes(item.id)) {
            item.icon = "end";
          }
          for (const user of item.users) {
            if (user){
              for (const audit of this.$store.state.auditList) {
                if (item.id === audit.nodeKey && audit.state === 'refuse'){
                  item.icon = "refuse";
                }
                if (audit.use != true && user.state == null  && audit.userId === user.id && item.id === audit.nodeKey){
                    audit.use = true;
                    user.state = audit.state;
                }
              }
            }
          }
        }
      })
    },
    startProcess(process,detail,processInstanceId) {
      this.initiator = detail.root[0].user;
      this.startUserId = detail.root[0].user.id;
      this.approver = [];
      this.process = JSON.parse(JSON.stringify(process));
      this.getProcess(this.process, [], this.approver);
      this.selectApprover(processInstanceId);
    },
    getProcess(process, data, cc) {
      if (null != process && undefined != process) {
        if ("ROOT" === process.type) {
          //发起人节点
          this.getRootNode(cc, process);
        } else if ("APPROVAL" === process.type) {
          //审批节点
          this.getApprovalNode(cc, process);
        } else if ("CC" === process.type) {
          this.getCcNode(cc, process);
        } else if ("CONDITIONS" === process.type) {
          //判断是否符合条件符合走条件分支,否则继续递归子分支
          if (null != process.branchs && undefined != process.branchs) {
            this.getConditionNode(cc, process);
          }
        } else if ("CONCURRENTS" === process.type) {
          this.getConcurrentNode(cc, process);
        }
        if (null != process.children && undefined != process.children) {
          this.getProcess(process.children, data, cc);
        }
      }
    },
    //封装开始节点
    getRootNode(cc, process) {
      cc.push({
        id: process.id,
        title: process.name,
        name: "发起人",
        icon: "el-icon-user-solid",
        isEdit: false,
        users: [this.initiator],
      });
    },
    //封装审批节点
    getApprovalNode(cc, process) {
      var data = {
        id: process.id,
        title: process.name,
        name: "审批人",
        type:process.props.assignedType,
        isEdit: false,
        multiple: false,
        mode: process.props.mode,
        users: [],
        desc: "",
      };
      //判断审批人类型
      switch (process.props.assignedType) {
        case "ASSIGN_USER":
          data.users = this.$deepCopy(process.props.assignedUser);
          data.desc = "指定审批人";
          break;
        case "ASSIGN_LEADER":
          data.desc = "指定部门的领导";
          break;
        case "SELF":
          data.users = [this.loginUser];
          data.desc = "发起人自己审批";
          break;
        case "SELF_SELECT":
          data.isEdit = true;
          data.multiple = process.props.selfSelect.multiple || false;
          data.desc = "自选审批人";
          break;
        case "LEADER_TOP":
          let leaderTop = process.props.leaderTop;
          if (leaderTop.endCondition == 'TOP'){
            data.desc = "连续多级主管审批";
          }else {
            data.leader = leaderTop.level;
            data.desc = "连续"+leaderTop.level+"级主管审批";
          }
          break;
        case "LEADER":
          data.leader = process.props.leader.level;
          data.desc = 1 === process.props.leader.level ? "主管审批" : "第".concat(process.props.leader.level, "级主管审批");
          break;
        case "ROLE":
          data.role = process.props.role;
          data.desc = "[".concat(
              (process.props.role || []).map(function (e) {
                return e.name;
              }),
              "]"
          );
          break;
        case "REFUSE":
          data.desc = "流程此处将被自动驳回";
          break;
      }
      cc.push(data);
    },
    getCcNode(cc, process) {
      var data = {
        id: process.id,
        title: process.name,
        icon: "el-icon-s-promotion",
        name: "抄送人",
        isEdit: process.props.shouldAdd,
        type: "org",
        multiple: true,
        desc: process.props.shouldAdd ? "可添加抄送人" : "",
        users: this.$deepCopy(process.props.assignedUser),
      };
      cc.push(data);
    },
    getConditionNode(cc, process) {
      for (var r = null, s = 0; s < process.branchs.length; s++) {
        for (
            var a = process.branchs[s], n = false, o = 0, i = 0;
            i < a.props.groups.length;
            i++
        ) {
          if (
              ((n = this.getConditionResultByGroup(a.props.groups[i])),
              "OR" === a.props.groupsType && n)
          ) {
            r = a;
            break;
          }
          "AND" === a.props.groupsType && n && o++;
        }

        if (r) {
          break;
        }
        if (o === a.props.groups.length) {
          r = a;
          break;
        }
      }
      var d = [];
      console.log(
          "符合分支条件,继续执行递归,获取符合条件下节点下的子节点!" +
          JSON.stringify(r)
      );
      r
          ? this.getProcess(r, d, cc)
          : console.log(
          "条件节点 "
              .concat(process.id, " => ")
              .concat(process.name, " 均不满足，无法继续"),
          process
          );
    },
    getConcurrentNode(cc, process) {
      var data = {
        id: process.id,
        title: process.name,
        name: "并行分支",
        icon: "el-icon-s-operation",
        isEdit: false,
        active: process.branchs[0].id,
        options: [],
        desc: "切换分支可显示对应执行流程",
        branchs: {},
      };
      cc.push(data);
      process.branchs.forEach((b) => {
        data.options.push({ id: b.id, title: b.name });
        this.$set(data.branchs, b.id, []);
        var d = [];
        this.getProcess(b.children, d, data.branchs[b.id]);
      });
    },
    getConditionResultByGroup: function (e) {
      var t = this,
          r = !1,
          s = 0;
      e.conditions.forEach(function (e) {
        return t.conditionFormItem.add(e.id);
      });

      for (var a = 0; a < e.conditions.length; a++) {
        var n = e.conditions[a];
        switch (n.valueType) {
          case "Number":
            console.log("这是number类型判断");
            r = this.numberCompare(n);
            break;
          case "String":
            console.log("这是string类型判断");
            break;
          case "Date":
            console.log("这是data类型判断");

            break;
          case "User":
            r = this.userCompare(n.value);
            break;
          case "Array":
            console.log("这是array类型判断");

            break;
        }
        if (r && "OR" === e.groupType) break;
        r && "AND" === e.groupType && s++;
      }
      return "AND" === e.groupType && (r = s === e.conditions.length), r;
    },
    numberCompare: function (e) {
      var t = this.formData[e.id];
      switch (e.compare) {
        case ">":
          return t > parseFloat(e.value[0]);
        case "<":
          return t < parseFloat(e.value[0]);
        case "=":
          return t === parseFloat(e.value[0]);
        case ">=":
          return t >= parseFloat(e.value[0]);
        case "<=":
          return t <= parseFloat(e.value[0]);
        case "B":
          return t > parseFloat(e.value[0]) && t < parseFloat(e.value[1]);
        case "AB":
          return t >= parseFloat(e.value[0]) && t < parseFloat(e.value[1]);
        case "BA":
          return t > parseFloat(e.value[0]) && t <= parseFloat(e.value[1]);
        case "ABA":
          return t >= parseFloat(e.value[0]) && t <= parseFloat(e.value[1]);
        case "IN":
          return e.value.indexOf(String(t)) > -1;
        default:
          return !1;
      }
    },
    userCompare(users) {
      for (const user of users) {
        if (user.id  === this.initiator.id){
          return true;
        }
      }
    }
  },
}
</script>

<style lang="less" scoped>
.card{
  box-shadow: 0px 0px 8px rgba(99, 99, 99, 0.1);
  margin: -10px 10px 0 0;
  padding:12px;
  border-radius: 8px;
.node-title{
  font-size: 15px;
  font-weight: bold;
}
.node-describe{
  color: #4b4b4b;
  font-size: 12px;
  font-weight: normal
}
.user-item{
  width: 60px;
  margin: 6px 0;
  text-align: center;
  position: relative;
.delete-user{
  position:absolute;
  top: -6px;
  right: 6px;
  color: red;
  background-color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  font-weight: normal;
  border-radius: 50%;
  padding: 2px;
}
.user-avatar{
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: #2a99ff;
}
.user-name{
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  font-size: 14px;
  color: #909399;
}
  .user-state{
    position:absolute;
    bottom: 13px;
    right: 6px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    padding: 0;
  }
}
.add-user{
  border: #909399 dashed 1px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 7px;
  font-weight: normal;
  color: #909399;
  margin: 6px 10px 0 10px;
}
}
.comment-item{
  background-color: #F5F7FA;
  margin: 10px 14px;
  border-radius: 12px;
  padding: 2px 10px;
}
.comment-info{
  background-color: white;padding: 10px;border-radius: 8px;font-size: 12px;color: #606266;
  height: 60px;
  overflow: auto;
  margin: 10px 0;
}
.attachment{
  background-color: white;
}
</style>