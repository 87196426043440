<template>
  <div class="approval" v-loading="submitLoading" element-loading-text="请稍等...">
      <sponsor-process
          ref="processForm"
          :code="selectForm.formId"
      ></sponsor-process>
<!--       <initiate-process ref="processForm" :code="selectForm.formId" v-if="openItemDl"></initiate-process>-->
    <div class="button-total">
      <el-button class="left-button" @click="cancel">取 消</el-button>
      <el-button class="right-button" @click="submitForm">提 交</el-button>
    </div>

  </div>
</template>

<script>
import {startProcess} from "@/api/design";
import SponsorProcess from "@/views/workspace/SponsorProcess";

export default {
  name: "startApply",
  components: { SponsorProcess },
  data() {
    return {
      selectForm: {},
      formItem: {},
      actives: [],
      submitLoading:false,
      formList: {
        list: [],
        inputs: "",
        searchResult: [],
      },
      pending: {
        list: [],
      },
    };
  },
  methods: {
    enterItem(item) {
      this.selectForm = item;
    },
    cancel(){
      this.submitLoading = false;
      this.$router.back()
    },
    submitForm() {
      this.submitLoading = true;
      const form = this.$refs.processForm.getForm();
      const strUserInfo = sessionStorage.getItem("user");
      let item = {form,
        processDefinitionId:form.processDefinitionId,
        formData:this.$refs.processForm.getFormData(),
        processUsers:this.$refs.processForm.getProcessUser(),
        startUserInfo:JSON.parse(strUserInfo),
      }
      this.$refs.processForm.validate((valid) => {
        if (!valid) {return this.$message.warning("请完善表单内容！");}
      });
      startProcess(item).then(() => {
        this.submitLoading = false;
        this.$message.success("提交成功");
        this.$router.back()
      });
    },
  },
  created() {
    const query = this.$route.query;
    this.selectForm = query.selectForm;
  }
};
</script>

<style lang="less" scoped>
.left-button{
  background-color: #D4D7DE;
  padding: 14px 40px;
  color: white;
  border-radius: 13px 0 0 13px;
  margin: 0;
}
.right-button{
  background-color: #409EFF;
  padding: 14px 40px;
  color: white;
  border-radius: 0 13px 13px 0;
  border: none;
  margin: 0;
}
.button-total{
  display: flex;
  justify-content: center;
  padding: 0px 0 30px 0
}
@media screen and (max-width: 800px) {
  .form-item {
    padding: 12px 10px !important;
    width: 150px !important;
    margin: 5px !important;

    &:hover {
      span:last-child {
        display: none !important;
      }
    }
  }
}
</style>
