<template>
  <w-dialog :border="false" closeFree  @ok="selectOk" :title="title" v-model="visible" :fullscreen="true">
    <div>
      <el-tabs v-model="titleIndex" :stretch="true" class="demo-tabs">
        <el-tab-pane label="选择" name="choice"></el-tab-pane>
        <el-tab-pane label="以选" name="second"></el-tab-pane>
      </el-tabs>
        <div v-if="titleIndex == 'choice'" class="picker">
          <el-input v-model="search" @input="searchUser" style="width: 100%;" size="small"
                    clearable placeholder="搜索校区名称" prefix-icon="el-icon-search"/>
          <div class="candidate" v-loading="loading">
            <div >
              <div v-show="!showUsers">
                <ellipsis hoverTip style="height: 20px;font-size: 12px" :row="1" :content="deptStackStr">
                  <i slot="pre" class="el-icon-office-building"></i>
                </ellipsis>
                <div style="margin-top: 5px;display: flex;justify-content: space-between">
                  <el-checkbox v-model="checkAll" @change="handleCheckAllChange" :disabled="!multiple">全选</el-checkbox>
                  <span v-show="deptStack.length > 0" class="top-dept" @click="beforeNode">上一级</span>
                </div>
              </div>
            </div>
            <div class="org-items">
              <el-empty :image-size="100" description="似乎没有数据" v-show="orgs.length === 0"/>
              <div v-for="(org, index) in orgs" :key="index" class="org-item" style="display:flex;" @click="selectChange(org)">
                <el-checkbox v-model="org.selected"></el-checkbox>
                <div class="name">{{ org.name }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="titleIndex == 'second'" class="org-items" style="height: 76vh;overflow: auto">
          <el-empty :image-size="100" style="margin-top: 100px" description=" " v-show="select.length === 0"/>
          <div v-for="(org, index) in select" :key="index" class="second-item">
            <div style="margin: auto 0">
              <i v-if="org.type == 'dept'" class="el-icon-folder-opened"></i>
              <i v-if="org.type == 'user'" class="el-icon-user"></i>
              <span style="margin-left: 10px">{{ org.name }}</span>
            </div>
            <div style="margin: auto 0;color: red" @click="noSelected(index)" class="el-icon-delete"></div>
          </div>
        </div>
    </div>

  </w-dialog>
</template>

<script>
import {selectCampus} from '@/api/org'

export default {
  name: "OrgPicker",
  components: {},
  props: {
    title: {
      default: '请选择',
      type: String
    },
    type: {
      default: 'org', //org选择部门/人员  user-选人  dept-选部门 role-选角色
      type: String
    },
    multiple: { //是否多选
      default: false,
      type: Boolean
    },
    selected: {
      default: () => {
        return []
      },
      type: Array
    },
  },
  data() {
    return {
      titleIndex:'choice',
      visible: false,
      loading: false,
      checkAll: false,
      nowDeptId: null,
      isIndeterminate: false,
      searchUsers: [],
      nodes: [],
      select: [],
      search: '',
      deptStack: []
    }
  },
  computed: {
    deptStackStr() {
      return String(this.deptStack.map(v => v.name)).replaceAll(',', ' > ')
    },
    orgs() {
      return !this.search || this.search.trim() === '' ? this.nodes : this.searchUsers
    },

    showUsers(){
      return this.search || this.search.trim() !== ''
    }
  },
  methods: {
    show(elTabsName) {
      if (elTabsName){
        this.titleIndex = elTabsName;
      }
      this.visible = true
      this.init()
      this.getOrgList()
    },
    getOrgList() {
      this.loading = true
      selectCampus({deptId: this.nowDeptId, type: this.type}).then(rsp => {
        this.loading = false
        this.nodes = rsp.data
        this.selectToLeft()
      }).catch(err => {
        this.loading = false
        this.$message.error(err.response.data)
      })
    },
    getShortName(name) {
      if (name) {
        return name.length > 2 ? name.substring(1, 3) : name;
      }
      return '**'
    },
    searchUser() {
      let userName = this.search.trim()
      this.searchUsers = []
      this.loading = true
      selectCampus({name: userName}).then(rsp => {
        this.loading = false
        this.searchUsers = rsp.data
        this.selectToLeft()
      }).catch(err => {
        this.loading = false
        this.$message.error("接口异常")
      })
    },
    selectToLeft() {
      let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers;
      nodes.forEach(node => {
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            node.selected = true;
            break;
          } else {
            node.selected = false;
          }
        }
      })
    },
    selectChange(node) {
      console.log(node.selected)
      if (node.selected) {
        this.checkAll = false;
        for (let i = 0; i < this.select.length; i++) {
          if (this.select[i].id === node.id) {
            this.select.splice(i, 1);
            break;
          }
        }
        node.selected = false;
      } else {
        node.selected = true
        let nodes = this.search.trim() === '' ? this.nodes : this.searchUsers;
        if (!this.multiple) {
          nodes.forEach(nd => {
            if (node.id !== nd.id) {
              nd.selected = false
            }
          })
        }
        if (node.type === 'dept') {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.unshift(node);
          }
        } else {
          if (!this.multiple) {
            this.select = [node]
          } else {
            this.select.push(node);
          }
        }
      }
    },
    noSelected(index) {
      let nodes = this.nodes;
      for (let f = 0; f < 2; f++) {
        for (let i = 0; i < nodes.length; i++) {
          if (nodes[i].id === this.select[index].id) {
            nodes[i].selected = false;
            this.checkAll = false;
            break;
          }
        }
        nodes = this.searchUsers;
      }
      this.select.splice(index, 1)
    },
    handleCheckAllChange() {
      this.nodes.forEach(node => {
        if (this.checkAll) {
          if (!node.selected) {
            node.selected = true
            this.select.push(node)
          }
        } else {
          node.selected = false;
          for (let i = 0; i < this.select.length; i++) {
            if (this.select[i].id === node.id) {
              this.select.splice(i, 1);
              break;
            }
          }
        }
      })
    },
    nextNode(node) {
      this.nowDeptId = node.id
      this.deptStack.push(node)
      this.getOrgList()
    },
    beforeNode() {
      if (this.deptStack.length === 0) {
        return;
      }
      if (this.deptStack.length < 2) {
        this.nowDeptId = null
      } else {
        this.nowDeptId = this.deptStack[this.deptStack.length - 2].id
      }
      this.deptStack.splice(this.deptStack.length - 1, 1);
      this.getOrgList()
    },
    recover() {
      this.select = []
      this.nodes.forEach(nd => nd.selected = false)
    },
    selectOk() {
      this.$emit('ok', Object.assign([], this.select.map(v => {
        v.avatar = undefined
        return v
      })))
      this.visible = false
      this.recover()
    },
    clearSelected(){
      this.$confirm('您确定要清空已选中的项?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.recover()
      })
    },
    close() {
      this.$emit('close')
      this.recover()
    },
    init() {
      this.checkAll = false;
      this.nowDeptId = null;
      this.deptStack = [];
      this.nodes = []
      this.select = Object.assign([], this.selected)
      this.selectToLeft()
    }
  }
}
</script>

<style lang="less" scoped>

@containWidth: 350px;

.candidate, .selected {
  position: absolute;
  display: inline-block;
  width: @containWidth;
}

.picker {
  position: relative;
  text-align: left;
  .candidate {
    border-radius: 10px;
    left: 0;
    top: 50px;
    .role-header{
      padding: 10px !important;
      margin-bottom: 5px;
      border-bottom: 1px solid #e8e8e8;
    }
    .top-dept{
      margin-left: 20px;
      cursor: pointer;
      color:#38adff;
    }
    .next-dept {
      float: right;
      color: @theme-primary;
      cursor: pointer;
    }

    .next-dept-disable {
      float: right;
      color: #8c8c8c;
      cursor: not-allowed;
    }

    & > div:first-child {
      padding: 5px 10px;
    }

  }

  .selected {
    right: 0;
    top: 0;
  }

  .org-items {
    overflow-y: auto;
    height: 410px;
    .el-icon-close {
      position: absolute;
      right: 5px;
      cursor: pointer;
      font-size: larger;
    }
    .org-dept-item {
      padding: 6px 5px;

      & > div {
        display: inline-block;

        & > span:last-child {
          position: absolute;
          right: 5px;
        }
      }
    }

    .org-role-item {
      display: flex;
      align-items: center;
      padding: 10px 5px;
    }

    /deep/ .org-user-item {
      display: flex;
      align-items: center;
      padding: 5px;

      & > div {
        display: inline-block;
      }

      .avatar {
        width: 35px;
        text-align: center;
        line-height: 35px;
        background: @theme-primary;
        color: white;
        border-radius: 50%;
      }
    }

    /deep/ .org-item {
      margin: 5px;
      position: relative;
      padding: 2px;
      .el-checkbox {
        margin:2px 8px 0 0;
      }

      .name {
      }

      &:hover {
        background: #f1f1f1;
      }
    }
  }
}

.selected {
  border-left: none;
  .count {
    width: calc(@containWidth - 20px);
    padding: 10px;
    display: inline-block;
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 5px;
    & > span:nth-child(2) {
      float: right;
      color: #c75450;
      cursor: pointer;
    }
  }
}

/deep/ .el-dialog__body {
  padding: 10px 20px;
}

.disabled{
  cursor: not-allowed !important;
  color: #8c8c8c !important;
}

::-webkit-scrollbar {
  float: right;
  width: 4px;
  height: 4px;
  background-color: white;
}
.second-item{
  display: flex;
  justify-content: space-between;
  background-color: #F5F7FA;
  margin: 10px 0;
  padding: 10px 14px;
  border-radius: 10px;
  align-content: center;
  font-size: 14px;
}
::-webkit-scrollbar-thumb {
  border-radius: 16px;
  background-color: #efefef;
}
</style>
