<template>
    <div style="background-color: #F0F2F5;" v-loading="loading">
    <form-render
      style="height: 100%"
      class="process-form"
      mode="PC"
      ref="form"
      :forms="forms"
      v-model="formData"
    />
    <approver ref="approver"/>
    <process-form class="function" :processInfo="processInfo" :type="type"></process-form>
    </div>
</template>

<script>
import { getProcessInstanceInfo } from "@/api/design";
import FormRender from "@/views/common/form/FormRender";
import { flatFormItem } from "../form";
import Approver from "../approver/Approver";
import ProcessForm from "../../workspace/process/ProcessForm";
import Approval from "../approval";
export default {
  components: {Approver, Approval, FormRender, ProcessForm },
  data() {
    return {
      loading: false,
      processInstanceId: "",
      taskId: "",
      form: null,
      formData: {},
      currentNode: {},
      processInfo: "",
      cc:[]
    };
  },
  computed: {
    forms() {
      console.log(this.$store.state.design.formItems)
      return this.$store.state.design.formItems;
    },
    type() {
      return this.$route.query.type;
    },
  },
  methods: {
    getProcessInfo() {
      this.loading = true;
      getProcessInstanceInfo(this.processInstanceId, this.taskId).then((rsp) => {
          const form = { ...rsp.data.result.processTemplates };
          const currentNode = { ...rsp.data.result?.currentNode };
          const formData = { ...rsp.data.result.formData };

          form.logo = JSON.parse(form.logo);
          form.settings = JSON.parse(form.settings);
          form.process = JSON.parse(form.process);
          const perms = currentNode?.props?.formPerms || [];
          // 表单项 从json转换为数组 用于渲染表单 扁平化处理 去除了spanLayout
          const formItems = flatFormItem(JSON.parse(form.formItems));
          // item显示状态映射关系
          const itemStatusMap = new Map(perms.map((it) => [it.id, it.perm]));
          const items = formItems.map((item) => {
              // perm说明 只读R  编辑E  隐藏H
              const perm = itemStatusMap.get(item.id);
              //暂时先全部只读--- 后续在尝试节点的判断
              item.props.readerMode = true;
              return item;
              // if (perm === "E") {
              //   return item;
              // } else if (perm === "R") {
              //
              //   return item;
              // } else if (perm === "H") {
              //   return undefined;
              // }
            }).filter(Boolean);

          form.formItems = items;
          this.$store.state.design = form;
          this.$store.state.endList = rsp.data.result.endList;
          this.$store.state.runningList = rsp.data.result.runningList;
          this.$store.state.noTakeList = rsp.data.result.noTakeList;
          this.$store.state.detailVOList = rsp.data.result.detailVOList;
          this.$store.state.auditList = rsp.data.result.auditList;
          this.currentNode = currentNode;
          this.formData = formData;
          console.log(formData)
          this.processInfo.formData = formData;
          this.processInfo.signFlag = rsp.data.result.signFlag;
          this.form = form;
        console.log(form)
          this.$refs.approver.startProcess(form.process,rsp.data.result.detailVOList,this.processInstanceId);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  beforeMount() {
    this.processInstanceId = this.$route.query.processInstanceId;
    this.taskId = this.$route.query.taskId;
    const str = sessionStorage.getItem("user");
    const currentUserInfo = JSON.parse(str);
    currentUserInfo.id = currentUserInfo.id.toString();
    this.processInfo = {
      processInstanceId: this.processInstanceId,
      taskId: this.taskId,
      signFlag: null,
      currentUserInfo: currentUserInfo,
    };
  },
  mounted() {
    this.getProcessInfo();
  },
};
</script>

<style lang="less" scoped>
.function{
  position: fixed;
  bottom: 10px;
  z-index:999
}
.process-view {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &__header {
    margin-bottom: 10px;
  }

  &__tabs {
    flex: 1;
    /deep/ .el-tabs__content {
      height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }
}
</style>
