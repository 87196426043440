<template>
  <div style="max-width: 350px">
    <div v-if="mode === 'DESIGN'">
      <div class="choice-item" style="margin-left: 6px" disabled>+</div>
    </div>
    <div v-else>
      <div v-if="!_value.length>0" class="choice-item" @click="$refs.orgPicker.show()">+</div>
      <div style="margin-top: 5px;" class="justify-between">
        <div v-for="(user, i) in _value" :key="i+'dept'" style="flex: 0 0 20%;">
          <div v-if="_value.length>0 && i<8" class="campus-item" @click="$refs.orgPicker.show('second')">
            <div class="choice-item-name">{{user.name}}</div>
          </div>
        </div>
        <div v-if="_value.length>8" class="omit" @click="$refs.orgPicker.show('second')" style="height: 10px;">
          <span>.</span><span style="margin: 0 3px">.</span><span>.</span>
        </div>
        <div v-for="(user, i) in 10" :key="i" style="flex: 0 0 23%;"></div>
      </div>
      <dynamic-picker type="dept" :multiple="multiple" ref="orgPicker" :selected="_value" @ok="selected"></dynamic-picker>
    </div>
  </div>
</template>

<script>
import componentMinxins from '../../ComponentMinxins'
import dynamicPicker from "@/components/common/DynamicPicker";

export default {
  mixins: [componentMinxins],
  name: "DeptPicker",
  components: {dynamicPicker},
  props: {
    value:{
      type: Array,
      default: () => {
        return []
      }
    },
    placeholder: {
      type: String,
      default: '请选择部门'
    },
    multiple:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOrgSelect: false
    }
  },
  methods: {
    selected(values){
      this.showOrgSelect = false
      this._value = values
    },
    delDept(i){
      this._value.splice(i, 1)
    }
  }
}
</script>

<style scoped>
.placeholder{
  margin-left: 10px;
  color: #adabab;
  font-size: smaller;
}
.choice-item{
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  border: 1px dashed #CDD0D6;
  border-radius: 5px;
  color: #909399;
}
.campus-item{
  width: 60px;
  text-align: center;
  color: white;
  border-radius: 5px;
  background-color: #EBEEF5;
  padding: 0 10px;
  margin: 4px 0;
}
.choice-item-name{
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
  font-size: 14px;
  width: 60px;
  color: #909399;
  height: 28px;
  line-height: 28px;
}
.omit{
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #909399;
  width: 100%;
  margin-top: -6px;
}
.justify-between{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between
}
</style>
